'use client';

import { useEffect, useState } from 'react';

import { type TUserRole, userRole } from '@shared/types';
import { getApiToken } from '@shared/api/utils/get-api-token';

export const useRole = () => {
	const [role, setRole] = useState<TUserRole>();

	useEffect(() => {
		const token = getApiToken();

		if (token) {
			// See entity root file for more info
			setRole(token.roles[0]);
		}
	}, []);

	return {
		role,
		isMarketer: role === userRole.marketer || role === userRole.marketerVip,
		isAffiliate: role === userRole.affiliate,
		isManager: role === userRole.manager,
	};
};
